<template>
 <div class="border-2 border-gray-300 border-dashed mx-10">
  <div class="px-6 py-5 text-left">
   <div class="text-center">
    <font-awesome-icon
     :icon="
      this.$route.path.includes('answered-unanswered') || this.$route.name === 'ExpertStatisticsCallDurations'
       ? 'chart-bar'
       : 'chart-pie'
     "
     class="mx-auto h-10 w-10 text-gray-400"
     aria-hidden="true"
    />
    <h3 class="text-xl font-medium text-gray-900">
     {{ $t("warning.noDataFoundDashboardTitle") }}
    </h3>

    <p class="text-base text-gray-500">
     {{ $t("warning.noDataFoundDashboardDescription") }}
    </p>
   </div>
  </div>
  <div class="px-6 py-5 text-left"></div>

  <!-- <span>
      <img
        class="img_auto_width"
        :src="
          this.$route.path.includes('answered-unanswered') ? img.src2 : img.src
        "
        :alt="img.alt"
      />
  
    </span> -->
 </div>
</template>

<script>
export default {
 name: "EmptyStateDashboard",
 props: ["urlType"],
 data() {
  return {
   img: {
    src: require("../../assets/empty-state-dashboard.png"),
    src2: require("../../assets/empty-state-dashboard-2.png"),
    alt: "empty-state-dashboards",
   },
  };
 },
};
</script>

<style>
.img_auto_width {
 width: 100%;
}
</style>
