<template>
 <div v-if="groupData && Object.keys(groupData).length > 0">
  <h3 class="text-lg font-medium leading-6 text-gray-900">
   {{ $t("groupData") }}
  </h3>
  <dl :class="`mb-5 grid grid-cols-1 gap-1 sm:grid-cols-${statsOrder.length}`">
   <div
    v-for="stat in statsOrder"
    :key="stat.order"
    class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-4">
    <dt class="truncate text-sm font-medium text-gray-500">
     {{ $t(stat.key) }}
    </dt>
    <dd class="mt-1 text-xl font-semibold tracking-tight text-gray-900">
     {{ stat.key === 'talking' || stat.key === 'avgDurationTotal' ? formatSecsToHourMinAndSecs(groupData[stat.key]) : groupData[stat.key] }}
    </dd>
   </div>
  </dl>
 </div>
</template>

<script>
const statsOrder = [
 { key: "calls", order: 0 },
 { key: "answered", order: 1 },
 { key: "unanswered", order: 2 },
 { key: "avgDurationTotal", order: 3 },
 { key: "talking", order: 4 },
];
export default {
 props: ["data"],
 data() {
  return {
   statsOrder,
  };
 },
 computed: {
  groupData() {
   let obj = {};
   let arr = [];
   let objData = {};
   if (this.data && this.data.length > 0) {
    let keys = Object.keys(this.data[0]);
    keys.forEach(key => {
     if (key !== "extension" && key !== "agent" && !key.includes("avg")) {
      obj[key] = 0;
     }
    });
    this.data.forEach(el => {
     for (const objKey in obj) {
      if (Object.hasOwnProperty.call(obj, objKey)) {
       obj[objKey] = obj[objKey] + el[objKey];
      }
     }
    });
   }
   for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
     //  let objArr = {};
     objData[key] = key.includes("duration")
      ? (obj[key] / obj["calls"]).toFixed(0)
      : obj[key];
     objData["avgDurationTotal"] = obj["talking"]
      ? (obj["talking"] / obj["answered"]).toFixed(0)
      : 0;
     //  objData["stat"] = key.includes("duration")
     //   ? (obj[key] / obj["calls"]).toFixed(0)
     //   : obj[key];
     if (
      !key.includes("_") ||
      (key.includes("_") && key.includes("duration"))
     ) {
      //   arr.push(objArr);
     }
    }
   }
   console.log("groupData ~ objData:", objData);
   return objData;
  },
 },
};
</script>

<style></style>
